<template>
  <FabButton>
    <template #default="{ cssClasses }">
      <Button
        id="express-booking-toggle"
        ref="target"
        :theme="BUTTON_THEME.PRIMARY"
        :size="BUTTON_SIZE.LG"
        :class="cssClasses"
        class="w-16 justify-center leading-[18px] data-[prefix]:!pl-5 data-[prefix]:!pr-5 md:w-36 [&>span]:hidden [&>span]:text-left [&>span]:md:inline-flex [&>span]:md:w-16"
        :title="t('btn.toggle')"
        :truncate="false"
        :icon="SVG_ICON.EXPRESS_TICKET"
        :text="t('btn.expressBooking')"
        @click="toggle"
      />
    </template>
  </FabButton>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { toggle } = useExpressBooking()

defineOptions({
  name: 'ExpressBookingToggle',
})
</script>

<i18n>
de:
  btn:
    toggle: "Express Booking öffnen"
    expressBooking: "Express Booking"
es:
  btn:
    toggle: "Abrir reserva express"
    expressBooking: "Reserva express"
</i18n>
